import styled, { css } from 'styled-components';
import { breakpointsMedia } from '@nubank/nuds-web/styles/breakpoints';

export const StickyContainer = styled.div`
  position: fixed;
  z-index: 8;
  bottom: 0;
  left: 1.5rem;
  right: 1.5rem;
  display: inline-flex;

  button.ultraviolet{
    background-color: #290B4D;
  }

  ${breakpointsMedia({
    md: css`
      display: none;
    `,
  })}
`;

StickyContainer.displayName = 'StickyContainer';
