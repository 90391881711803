import React from 'react';

import { typeValidation } from '../../utils';

import ColumnTitleWrapper from './styles/ColumnTitleWrapper';

const ColumnTitle = props => (
  <ColumnTitleWrapper
    {...props}
  />
);

ColumnTitle.propTypes = {
  // eslint-disable-next-line react/no-unused-prop-types
  __TYPE: typeValidation('ColumnTitle'),
};

ColumnTitle.defaultProps = {
  __TYPE: 'ColumnTitle',
};

export default ColumnTitle;
