import { userSession } from '../../utils/userSession';

import fetchMetricsUrl from './repository/fetchMetricsURL';
import sendMetrics from './repository/sendMetrics';

export const createWebJourneyEvent = repository => (
  async (event, labels = {}) => {
    const shouldSendMetric = repository.userSession.get('shouldSendWebJourneyMetrics');
    if (!shouldSendMetric) return;
    const url = await repository.fetchMetricsUrl('web_journey_event');
    const body = {
      'session-id': repository.userSession.getId(),
      event,
      'event-at': new Date().toISOString(),
      labels,
    };
    await repository.sendMetrics(url, body);
  }
);

export default createWebJourneyEvent({
  fetchMetricsUrl,
  sendMetrics,
  userSession,
});
