import { fetchJson } from '@nubank/www-latam-commons/utils/http';
import { sentryException } from 'services/errorTracking/sentryException';

export const createSendMetrics = repository => (
  async (url, body) => {
    try {
      if (!url) throw new Error('Send metrics url is not defined');
      await repository.fetchJson(url, {
        method: 'POST',
        body,
      });
    } catch (error) {
      sentryException({
        error,
        flow: 'metrics_exporter',
        checkpoint: 'send_metrics',
        namespace: 'createSendMetrics()',
      });
      throw error;
    }
  }
);

export default createSendMetrics({
  fetchJson,
});
