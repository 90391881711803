import { fetchDiscoveryURLs } from '@nubank/www-latam-commons/domains/discovery/fetchDiscoveryURLs';

const METRICS_URL = 'metricsUrls';

async function getUrlFromDiscovery(repository, type) {
  const urlDiscovery = await repository.fetchDiscoveryURLs();
  const url = urlDiscovery[type];
  sessionStorage.setItem(
    METRICS_URL,
    JSON.stringify({ [type]: url }),
  );
  return url;
}

function getUrlFromSessionStorage(type) {
  const metricsUrlStorage = sessionStorage.getItem(METRICS_URL);
  const metricsUrl = JSON.parse(metricsUrlStorage);
  return metricsUrl && metricsUrl[type];
}

export const createFetchMetricsUrl = repository => (
  async type => getUrlFromSessionStorage(type) || getUrlFromDiscovery(repository, type)
);

export default createFetchMetricsUrl({
  fetchDiscoveryURLs,
});
