import styled, { css } from 'styled-components';
import Box from '@nubank/nuds-web/components/Box/Box';
import { spacing, nuDSColor } from '@nubank/nuds-web/styles/themeUtils';
import { breakpointsMedia } from '@nubank/nuds-web/styles/breakpoints';

const NavigationListSmallScreenWrapper = styled(Box)`
  width: 100vw;
  ${breakpointsMedia({
    xs: css`
      height: calc(100vh - 64px);
    `,
    md: css`
      height: calc(100vh - 56px);
    `,
  })}
  padding-bottom: ${spacing('20x')};
  overflow: scroll;
  scroll-behavior: smooth;
  ${({ $isHidden }) => ($isHidden && css`transform: translate3d(0, -100vh, 0);`)}
  transition: transform 0.2s ease-out;
  will-change: transform;
  background-color: ${nuDSColor('white')};
  position: absolute;
`;

NavigationListSmallScreenWrapper.displayName = 'NavigationListSmallScreenWrapper';

export default NavigationListSmallScreenWrapper;
