import React from 'react';
import PropTypes from 'prop-types';

import { HeaderContainer } from './style';

function Header(props) {
  const {
    isHidden,
    children,
    role,
    ariaExpanded,
    ariaLabel,
  } = props;

  return (
    <HeaderContainer
      isHidden={isHidden}
      role={role}
      ariaExpanded={ariaExpanded}
      ariaLabel={ariaLabel}
    >
      {children}
    </HeaderContainer>
  );
}

Header.defaultProps = {
  isHidden: false,
  children: undefined,
  role: undefined,
  ariaExpanded: undefined,
  ariaLabel: undefined,
};

Header.propTypes = {
  ariaExpanded: PropTypes.string,
  ariaLabel: PropTypes.string,
  children: PropTypes.node,
  isHidden: PropTypes.bool,
  role: PropTypes.string,
};

export default Header;
