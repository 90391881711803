import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'next/router';
import { useIntl } from 'react-intl';
import RadioButton from '@nubank/nuds-web/components/RadioButton/RadioButton';

import { maybeFormatMessage, AVAILABLE_LOCALES } from 'utils/i18nUtils';

import { SwitcherList, SwitcherListItem } from './styles/SwitcherList';

function LanguageSwitcher({ route }) {
  const intl = useIntl();

  return (
    <SwitcherList>
      {Object.values(AVAILABLE_LOCALES).map(({ code, name }) => {
        const ariaLabel = maybeFormatMessage(
          intl,
          'COMMON.LANGUAGE_SWITCHER.ARIA_LABEL',
          '',
          { locale: name },
        );

        return (
          <SwitcherListItem key={code}>
            <RadioButton
              ariaLabel={ariaLabel}
              forceRefresh
              isActive={code === intl.locale}
              locale={code}
              /* TODO: remove the logic bellow on the Emprestimo redesign experiment clean-up  */
              route={route === '/emprestimo/FuaMoDfiR8afm8z1C6uvVQ/1' && code === 'en' ? '/emprestimo' : route}
            >
              {name}
            </RadioButton>
          </SwitcherListItem>
        );
      })}
    </SwitcherList>
  );
}

LanguageSwitcher.propTypes = {
  route: PropTypes.string.isRequired,
};

export default withRouter(LanguageSwitcher);
