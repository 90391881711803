import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import Box from '@nubank/nuds-web/components/Box/Box';

import { typeValidation } from '../../utils';

const Section = forwardRef(({ children, ...restProps }, ref) => (
  <Box
    ref={ref}
    display="grid"
    overflow="hidden"
    gridTemplateColumns={{
      xs: 'auto',
      md: 'auto auto',
      lg: 'repeat( auto-fit, minmax(250px, 1fr) );',
    }}
    gridGap={{
      lg: '8x',
    }}
    paddingTop={{
      xs: '6x',
      md: '16x',
      lg: '0',
    }}
    backgroundColor="white.default"
    {...restProps}
  >
    {children}
  </Box>
));

Section.propTypes = {
  // eslint-disable-next-line react/no-unused-prop-types
  __TYPE: typeValidation('Section'),
  /** The content of the component, it can be either another component or raw text */
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
};

Section.defaultProps = {
  __TYPE: 'Section',
  children: null,
};

export default Section;
