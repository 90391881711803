import React from 'react';
import PropTypes from 'prop-types';
import Box from '@nubank/nuds-web/components/Box/Box';
import { isType } from '@nubank/nuds-web/utils/isType/isType';

import { typeValidation } from '../../utils';
import SectionLink from '../Navigation/SectionLink';

const Actions = ({ children, ...props }) => {
  const childrenList = isType('function', children)
    ? children({
      ActionLink: SectionLink,
    })
    : children;

  return (
    <Box
      display="flex"
      width="100%"
      height="100%"
      {...props}
    >
      {childrenList}
    </Box>
  );
};

Actions.propTypes = {
  // eslint-disable-next-line react/no-unused-prop-types
  __TYPE: typeValidation('Actions'),
  children: PropTypes.node,
};

Actions.defaultProps = {
  __TYPE: 'Actions',
  children: undefined,
};

export default Actions;
