import styled from 'styled-components';
import Link from '@nubank/nuds-web/components/Link/Link';
import { nuDSColor } from '@nubank/nuds-web/styles/themeUtils';

export const StyledLink = styled(Link)`
  margin-left: 2rem;
  align-self: center;

  &:hover, &:focus {
    svg path {
      fill: ${nuDSColor('primary', 'light')};
    }
  }
`;

StyledLink.displayName = 'StyledLink';
