import styled, { css } from 'styled-components';
import { nuDSColor, spacing } from '@nubank/nuds-web/styles/themeUtils';
import Link from '@nubank/nuds-web/components/Link/Link';
import { breakpointsMedia } from '@nubank/nuds-web/styles/breakpoints';

const ColumnLinkWrapper = styled(Link)`
  
  ${breakpointsMedia({
    xs: css`
      font-size: 1.25rem;
      display: flex;
      align-items: center;
      margin-bottom: ${spacing('5x')};
    `,
    md: css`
      font-size: 1.5rem;
    `,
    lg: css`
      display: inline-flex;
      margin-bottom: 0;
    `,
  })}
  
  &:hover {
    text-decoration: none;
    background: none;
    color: ${nuDSColor('primary')};
  }

  &:focus {
    text-decoration: none;
    background: none;
    outline-width: 0;
    color: ${nuDSColor('primary')};
  }

  &.menu-navigation-column-link__action {
    color: ${nuDSColor('black', 'defaultT20')};
  }
`;

ColumnLinkWrapper.defaultProps = {
  typographyProps: {
    variant: 'heading4',
    color: 'black',
  },
  activeClassName: 'menu-navigation-column-link__action',
};

ColumnLinkWrapper.displayName = 'ColumnLinkWrapper';

export default ColumnLinkWrapper;
