export const fontSize = {
  mobile: '24px',
  tablet: '16px',
  desktop: '18px',
};

export const logoHeight = {
  tablet: '40px',
  desktop: '45px',
};
