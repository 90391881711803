import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { breakpointsMedia } from '@nubank/nuds-web/styles/breakpoints';

export const Layout = styled.div`
  ${({ hasMenuHeightPadding }) => hasMenuHeightPadding && breakpointsMedia({
    xs: css`
      padding-top: ${({ theme }) => theme.menuHeight.mobile}px;
    `,
    md: css`
      padding-top: ${({ theme }) => theme.menuHeight.desktop}px;
    `,
    lg: css`
      padding-top: ${({ theme }) => theme.menuHeight.desktopLarge}px;
    `,
  })}
`;

Layout.propTypes = {
  hasMenuHeightPadding: PropTypes.bool.isRequired,
};
