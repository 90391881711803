import styled, { css } from 'styled-components';

export const HeaderWrapper = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  z-index: ${({ theme }) => theme.zIndexes.nav};
  will-change: transform;

  ${({ isHidden }) => isHidden && css`pointer-events: none;`};
`;
