import React from 'react';
import PropTypes from 'prop-types';
import Box from '@nubank/nuds-web/components/Box/Box';
import { isType } from '@nubank/nuds-web/utils/isType/isType';

import { typeValidation } from '../../utils';
import SectionLink from '../Navigation/SectionLink';

const ActionsSmallScreen = ({ className, children }) => {
  const childrenList = isType('function', children)
    ? children({
      ActionLink: SectionLink,
    })
    : children;

  return (
    <Box className={className}>{childrenList}</Box>
  );
};

ActionsSmallScreen.propTypes = {
  // eslint-disable-next-line react/no-unused-prop-types
  __TYPE: typeValidation('ActionsSmallScreen'),
  children: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object,
    PropTypes.func,
  ]),
  className: PropTypes.string,
};

ActionsSmallScreen.defaultProps = {
  children: undefined,
  className: undefined,
  __TYPE: 'ActionsSmallScreen',
};

export default ActionsSmallScreen;
