import styled from 'styled-components';
import Typography from '@nubank/nuds-web/components/Typography/Typography';

const ColumnTitleWrapper = styled(Typography)`
  font-size: 1rem;
  line-height: 125%;
  letter-spacing: -0.01em;
`;

ColumnTitleWrapper.defaultProps = {
  variant: 'subtitle1',
  color: 'black',
  marginBottom: '5x',
};

ColumnTitleWrapper.displayName = 'ColumnTitleWrapper';

export default ColumnTitleWrapper;
