import styled from 'styled-components';
import Button from '@nubank/nuds-web/components/Button/Button';
import { nuDSColor, spacing } from '@nubank/nuds-web/styles/themeUtils';

const StyledSearch = styled(Button)`
  background: ${nuDSColor('black.defaultT10')};
  color: ${nuDSColor('black.default')};
  margin-right: ${spacing('4x')};
  height: 2.5rem;
`;

export default StyledSearch;
