/* eslint-disable no-underscore-dangle */
import React from 'react';
import { v4 as uuid } from 'uuid';
import { isType } from '@nubank/nuds-web/utils/isType/isType';

export const typeOfComponent = component => component?.props?.__TYPE
  || component?.type?.toString().replace('Symbol(react.fragment)', 'react.fragment')
  || undefined;

export const getChildrenByType = (children, types) => React.Children.toArray(children)
  .filter(child => types.indexOf(typeOfComponent(child)) !== -1);

// eslint-disable-next-line consistent-return
export const typeValidation = expectedType => (props, propName, componentName) => {
  if (props[propName] !== expectedType) {
    return new Error(`'${propName}' in '${componentName}'\n\nYou may NOT pass in a prop value for '${propName}'. `
      + 'It had 2 underscores in the prop name for a reason. So, if you would kindly remove it, we can all go about our error free day.\n');
  }
};

const camelToSnakeCase = str => str && str
  .match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g)
  .map(x => x.toLowerCase())
  .join('_');

const createIDPattern = (str1, str2) => `${str1}__${camelToSnakeCase(str2)}`;

const normalizeProps = (
  componentChildren,
  componentIntlKey,
  componentType,
  callbackFn,
) => {
  if ((Boolean(componentIntlKey) && isType('undefined', componentChildren))) {
    const idNav = createIDPattern(componentType, componentIntlKey);
    return {
      idNav,
    };
  }

  if (isType('array', componentChildren)) {
    const hasIntlKeyFirstItem = Boolean(componentChildren[0].props.intlKey);
    const componentContentFirstItem = hasIntlKeyFirstItem
      ? componentChildren[0].props.intlKey
      : componentChildren[0].props.children;
    const idNav = createIDPattern(componentType, componentContentFirstItem);

    return {
      idNav,
      children: [...callbackFn(componentChildren)],
    };
  }

  if (isType('object', componentChildren)) {
    const hasIntlKeyInProps = Boolean(componentChildren?.props?.intlKey);
    const componentContentInProps = hasIntlKeyInProps
      ? componentChildren.props.intlKey
      : componentChildren.props.children;
    const idNav = createIDPattern(componentType, componentContentInProps);
    const idNavchildrenProps = createIDPattern(
      componentChildren.props.__TYPE, componentContentInProps,
    );
    return {
      idNav,
      children: [{
        props: {
          idNav: idNavchildrenProps,
          ...componentChildren.props,
        },
      }],
    };
  }

  const idNav = createIDPattern(componentType, componentChildren);
  return {
    idNav,
    children: componentChildren,
  };
};

export const normalizeNavigationStructureComponents = childrenProp => React.Children.map(
  childrenProp,
  child => {
    const isComponent = child && child.type;

    if (!isComponent) {
      return child;
    }

    let childProps = {};

    const {
      children,
      __TYPE,
      intlKey,
      ...restProps
    } = child.props;

    const formatedProps = normalizeProps(
      children,
      intlKey,
      __TYPE,
      normalizeNavigationStructureComponents,
    );

    const hastIntlKey = intlKey ? { intlKey } : {};

    childProps = {
      props: {
        __TYPE,
        ...hastIntlKey,
        ...restProps,
        ...formatedProps,
      },
    };

    return childProps;
  },
);

export const rendererComponents = (componentsMap, componentProps) => {
  const {
    children,
    __TYPE,
    ...restProps
  } = componentProps;

  if (isType('undefined', componentsMap[__TYPE])) return undefined;

  return React.createElement(
    componentsMap[__TYPE],
    {
      key: uuid(),
      ...restProps,
    },
    children && ((isType('string', children) || !isType('array', children))
      ? children
      : children.map(c => rendererComponents(componentsMap, c.props))),
  );
};

export const normalizeNavigationStructure = data => data
  .map(({ props: rootProps }) => rootProps.children
    .reduce((accValues, { props: itemProps }) => {
      if (itemProps.__TYPE === 'SectionTitle' || itemProps.__TYPE === 'SectionLink') {
        const hasIntlKey = Boolean(itemProps.intlKey);
        const componentContent = hasIntlKey
          ? itemProps.intlKey
          : itemProps.children;
        return {
          sectionId: rootProps.idNav,
          sectionText: componentContent,
          ...accValues,
        };
      }

      if (itemProps.__TYPE === 'Column') {
        const list = itemProps.children
          .filter(({ props: childPropsFiltered }) => childPropsFiltered.__TYPE === 'ColumnLink')
          .map(({ props: childProps }) => {
            const hasIntlKey = Boolean(childProps.intlKey);
            const childComponentContent = hasIntlKey
              ? childProps.intlKey
              : childProps.children;
            return {
              parentSectionId: accValues.sectionId,
              linkId: childProps.idNav,
              linkText: childComponentContent,
            };
          });

        // eslint-disable-next-line no-param-reassign
        accValues.linksTexts = accValues.linksTexts || [];
        const { sectionId, sectionText } = accValues;

        return {
          sectionId,
          sectionText,
          linksTexts: accValues.linksTexts.concat(list),
        };
      }

      return accValues;
    }, {}));

