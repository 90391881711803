import React from 'react';
import PropTypes from 'prop-types';
import NuLogo from '@nubank/nuds-web/components/NuLogo/NuLogo';

import { LogoWrapper } from './style';

function NavLogo({ className, onClick, ...props }) {
  return (
    <LogoWrapper className={className} onClick={onClick}>
      <NuLogo {...props} />
    </LogoWrapper>
  );
}

NavLogo.defaultProps = {
  className: 'NavLogo',
  onClick: () => {},
};

NavLogo.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
};

export default NavLogo;
