import Queue from 'queue';

import event from 'domains/webJourneyMetrics/event';
import start from 'domains/webJourneyMetrics/start';

/* We're using a Queue with concurrency 1 to ensure that the events will be sent in order.
   Also, on any error we stop the queue to prevent journey inconsistencies */
const webJourneyEventsQueue = new Queue({ concurrency: 1, autostart: true });
webJourneyEventsQueue.addListener('error', () => webJourneyEventsQueue.stop());

export const webApplication = {
  start: () => {
    webJourneyEventsQueue.push(() => start('web-application'));
  },
  startedEvent: () => {
    const [path] = window.location.href.split('?');
    webJourneyEventsQueue.push(() => event('web-application-started', { web_page: path }));
  },
  finishedEvent: () => {
    webJourneyEventsQueue.push(() => event('web-application-finished'));
  },
};

export const webProductOffer = {
  start: () => {
    webJourneyEventsQueue.push(() => start('web-product-offer'));
  },
  startedEvent: () => {
    const [path] = window.location.href.split('?');
    webJourneyEventsQueue.push(() => event('web-product-offer-started', { web_page: path }));
  },
  renderedEvent: ({ analysisResult }) => {
    const templateKey = analysisResult?.template?.id || 'no-template-id';
    webJourneyEventsQueue.push(() => event('web-product-offer-rendered', { template_id: templateKey }));
  },
  ctaClickedEvent: () => {
    webJourneyEventsQueue.push(() => event('web-product-offer-cta-clicked'));
  },
};
