import React from 'react';
import Box from '@nubank/nuds-web/components/Box/Box';

import { trackingClickMenu } from 'tracking/menu/clickInteraction';
import { useSearchContext } from 'components/SearchScreen/SearchContext';

import StyledSearch from './styles/Button';

const SearchButton = () => {
  const { openSearch } = useSearchContext();

  const handleClick = () => {
    openSearch();
    trackingClickMenu('search');
  };

  return (
    <Box
      display="flex"
      justifyContent="center"
      width="100%"
    >
      <StyledSearch
        variant="contained"
        styleVariant="black"
        intlKey="COMMON.SEARCH.CTA"
        iconProps={{ name: 'search', title: '' }}
        onClick={handleClick}
      />
    </Box>
  );
};

export default SearchButton;
