import React from 'react';

import { useApplicationFormDrawerContext } from 'patterns/ApplicationFormDrawer/ApplicationFormContext';
import { getProspectType } from 'utils/prospectTypes';
import { trackingClickMenu } from 'tracking/menu/clickInteraction';
import { trackOpenedWidget } from 'tracking/application/widget';

import { StyledApplicationButton } from './styles/ApplicationButton';

const ApplicationButton = () => {
  const {
    hasApplicationFormDrawer,
    isDrawerOpen,
    toggleDrawer,
    prospectTypeKey,
  } = useApplicationFormDrawerContext();
  const prospectType = getProspectType(prospectTypeKey);

  const handleClick = e => {
    trackOpenedWidget({
      prospectType: prospectType.type,
      buttonIndex: 1,
    });
    trackingClickMenu('open_widget');
    toggleDrawer();
    e.target.focus();
  };

  return hasApplicationFormDrawer ? (
    <StyledApplicationButton
      id="widget-nav-toggle-button"
      className={prospectType.extraClass ? prospectType.extraClass : ''}
      onClick={handleClick}
      aria-expanded={isDrawerOpen}
      intlKey={prospectType.buttonLabel}
    />
  ) : null;
};

export default ApplicationButton;
