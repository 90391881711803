/* eslint-disable react/prop-types */
import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { NuDsContext } from '@nubank/nuds-web/components/NuDSProvider/NuDSProvider';
import { useRouter } from 'next/router';
import Footer from '@nubank/nuds-web/patterns/Footer/Footer';
import Icon from '@nubank/nuds-web/components/Icon/Icon';
import NuLogo from '@nubank/nuds-web/components/NuLogo/NuLogo';
import Typography from '@nubank/nuds-web/components/Typography/Typography';

import AppStoreBadge from 'components/AppStoreBadge/AppStoreBadge';
import LanguageSwitcher from 'components/LanguageSwitcher/LanguageSwitcher';

import { StyledLink } from './styles/StyledLink';
import { StyledAppLink } from './styles/StyledAppLink';

function FooterBrazil({
  navigation,
  company,
  languageSwitcher,
}) {
  const { pathname } = useRouter();
  const { formatMessage } = useContext(NuDsContext);

  const socialMediaIcons = formatMessage({ id: 'cms.footer.socialMediaIcons' }) || [];
  const navigationGroup = formatMessage({ id: 'cms.footer.footerNavigationGroup' }) || [];

  function GenerateAppLink({ item }) {
    return (
      <StyledAppLink
        href={item.appstoreLink}
        intlAriaKey={item.ariaText}
      >
        <AppStoreBadge platform={item.platform} />
      </StyledAppLink>
    );
  }

  function GenerateLinkAndText({
    item,
    index,
    itemIndex,
    component,
  }) {
    const Component = component;
    return (
      <Component
        variant={item.variant}
        href={item.itemLink}
        intlKey={`cms.footer.footerNavigationGroup[${index}].categoryItems[${itemIndex}].itemName`}
        forceRefresh
      />
    );
  }

  function GenerateText({ item, component }) {
    const Component = component;
    return (
      <Component>{item.itemName}</Component>
    );
  }

  function returnComponentType(item, components = {}) {
    if (item.platform) {
      return [GenerateAppLink, () => { }];
    }
    if (item.itemLink) {
      return [GenerateLinkAndText, components.ColumnLink];
    }
    return [GenerateText, components.ColumnText];
  }

  return (
    <Footer>
      {navigation && (
        <Footer.Navigation data-testid="footer-navigation">
          {({
            Column,
            ColumnTitle,
            ColumnText,
            ColumnLink,
          }) => (
            <>
              {navigationGroup.map((_, index) => (
                <Column>
                  <ColumnTitle intlKey={`cms.footer.footerNavigationGroup[${index}].categoryName`} />
                  {formatMessage({ id: `cms.footer.footerNavigationGroup[${index}].categoryItems` }).map((item, itemIndex) => {
                    // eslint-disable-next-line max-len
                    const [Component, componentProp] = returnComponentType(item, { ColumnLink, ColumnText });
                    return (
                      <Component
                        component={componentProp}
                        index={index}
                        itemIndex={itemIndex}
                        item={item}
                      />
                    );
                  })}
                </Column>
              ))}
            </>
          )}
        </Footer.Navigation>
      )}

      {company && (
        <Footer.Company data-testid="footer-company">
          {({
            CompanyInfo,
            Social,
          }) => (
            <>
              <NuLogo size="small" />

              <CompanyInfo>
                <Typography variant="button" intlKey="cms.footer.legalInfo" />
                <br />
                <Typography variant="button" intlKey="cms.footer.addressInfo" />
              </CompanyInfo>

              <Social>
                {socialMediaIcons.map(item => (
                  <StyledLink
                    href={item.link}
                    intlAriaKey={item.name}
                  >
                    <Icon color="white" name={item.iconName} />
                  </StyledLink>
                ))}
              </Social>
            </>
          )}
        </Footer.Company>
      )}

      {languageSwitcher && (
        <Footer.Section compact data-testid="footer-language-switcher">
          <LanguageSwitcher route={pathname} />
        </Footer.Section>
      )}
    </Footer>
  );
}

FooterBrazil.propTypes = {
  company: PropTypes.bool,
  languageSwitcher: PropTypes.bool,
  navigation: PropTypes.bool,
};

FooterBrazil.defaultProps = {
  company: true,
  languageSwitcher: true,
  navigation: true,
};

export default React.memo(FooterBrazil);
