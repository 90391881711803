import React from 'react';
import PropTypes from 'prop-types';

import { ButtonToggleStyled } from './styles';

function ButtonToggle(props) {
  const {
    onMenuToggleClick,
    isMobileNavOpen,
    className,
    iconProps,
  } = props;

  const iconTitle = isMobileNavOpen
    ? iconProps.titleClose
    : iconProps.titleOpen;

  return (
    <ButtonToggleStyled
      variant="basic"
      styleVariant="black"
      onClick={onMenuToggleClick}
      className={isMobileNavOpen ? className : `${className} menu--button-toggle__close`}
      iconProps={{
        name: isMobileNavOpen ? 'x' : 'menu',
        title: iconTitle,
      }}
    />
  );
}

ButtonToggle.defaultProps = {
  isMobileNavOpen: false,
  className: undefined,
  iconProps: {
    titleOpen: 'Open',
    titleClose: 'Close',
  },
};

ButtonToggle.propTypes = {
  className: PropTypes.string,
  iconProps: PropTypes.shape({
    titleClose: PropTypes.string,
    titleOpen: PropTypes.string,
  }),
  isMobileNavOpen: PropTypes.bool,
  onMenuToggleClick: PropTypes.func.isRequired,
};

export default ButtonToggle;
