import PropTypes from 'prop-types';

import { HeaderWrapper } from './style';

HeaderWrapper.defaultProps = {
  headerStatus: {},
  isHidden: false,
};

HeaderWrapper.propTypes = {
  isHidden: PropTypes.bool,
};

export default HeaderWrapper;
