import styled from 'styled-components';
import { tablet768 } from '@nubank/nuds-web/styles/breakpoints';

export const SwitcherList = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;

  ${tablet768`
    justify-content: flex-end;
  `}
`;

SwitcherList.displayName = 'SwitcherList';

export const SwitcherListItem = styled.li`
  display: inline-block;
  padding-bottom: 3.75rem;

  & + & {
    margin-left: ${2.5 * 16}px;
  }

  a {
    display: flex;
    align-items: center;
  }
`;

SwitcherListItem.displayName = 'SwitcherListItem';
