import { sendEvent } from '@nubank/www-latam-commons/services/analytics';

const checkEventAnatomy = (payload = {}) => Boolean(payload.prospectType);

export const trackApplicationEvent = event => payload => {
  if (!checkEventAnatomy(payload)) {
    if (process.env.NODE_ENV === 'development') {
      console.warn(`The prospect_type key is missing on the ${event} event payload. Application events must have this key.`);
    }

    return;
  }

  sendEvent(event, payload);
};
