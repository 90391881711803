import React from 'react';
import styled, { css } from 'styled-components';
import Typography from '@nubank/nuds-web/components/Typography/Typography';
import { breakpointsMedia } from '@nubank/nuds-web/styles/breakpoints';

import { typeValidation } from '../../utils';

export const TypographyStyled = styled(Typography)`
  ${breakpointsMedia({
    xs: css`
      font-size: 2.25rem;
    `,
    md: css`
      font-size: 4rem;
    `,
  })}
`;

const SectionTitle = props => (
  <TypographyStyled
    className="menu-section-title"
    display={{ lg: 'none' }}
    color="black"
    variant="heading2"
    {...props}
  />
);

SectionTitle.propTypes = {
  // eslint-disable-next-line react/no-unused-prop-types
  __TYPE: typeValidation('SectionTitle'),
};

SectionTitle.defaultProps = {
  __TYPE: 'SectionTitle',
};

export default SectionTitle;
