import styled from 'styled-components';
import Box from '@nubank/nuds-web/components/Box/Box';
import { spacing } from '@nubank/nuds-web/styles/themeUtils';

export const ActionsWrapper = styled(Box)`
  li:nth-child(n+1):last-child {
    margin-left: ${spacing('4x')};
  }
`;

ActionsWrapper.displayName = 'ActionsWrapper';
