import styled from 'styled-components';
import Button from '@nubank/nuds-web/components/Button/Button';

export const ButtonToggleStyled = styled(Button)`
  &.menu--button-toggle__close {
    svg {
      width: 20px;
      height: 20px;
    }
  }
`;

ButtonToggleStyled.displayName = 'ButtonToggleStyled';
