import React from 'react';
import PropTypes from 'prop-types';
import Box from '@nubank/nuds-web/components/Box/Box';

import { typeValidation } from '../../utils';

const Logo = ({ className, children }) => (
  <Box className={className}>{children}</Box>
);

Logo.propTypes = {
  // eslint-disable-next-line react/no-unused-prop-types
  __TYPE: typeValidation('Logo'),
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

Logo.defaultProps = {
  className: undefined,
  __TYPE: 'Logo',
};

export default Logo;
