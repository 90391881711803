import React from 'react';
import Box from '@nubank/nuds-web/components/Box/Box';

import { typeValidation } from '../../utils';

function Column(props) {
  return (
    <Box
      paddingTop={{
        xs: '5x',
        lg: '12x',
      }}
      {...props}
    />
  );
}

Column.propTypes = {
  // eslint-disable-next-line react/no-unused-prop-types
  __TYPE: typeValidation('Column'),
};

Column.defaultProps = {
  __TYPE: 'Column',
};

export default Column;
