import React from 'react';

import { typeValidation } from '../../utils';

import SectionLinkWrapper from './styles/SectionLinkWrapper';

const SectionLink = props => (
  <SectionLinkWrapper
    className="menu-section-link"
    {...props}
  />
);

SectionLink.propTypes = {
  // eslint-disable-next-line react/no-unused-prop-types
  __TYPE: typeValidation('SectionLink'),
};

SectionLink.defaultProps = {
  __TYPE: 'SectionLink',
};

export default SectionLink;
