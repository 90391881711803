import React from 'react';
import PropTypes from 'prop-types';

import AppleStoreIcon from './icons/AppleStoreIcon';
import AppleStoreIconLight from './icons/AppleStoreIconLight';
import GooglePlayIcon from './icons/GooglePlayIcon';
import GooglePlayIconLight from './icons/GooglePlayIconLight';

const Stores = {
  android: {
    light: GooglePlayIconLight,
    dark: GooglePlayIcon,
  },
  ios: {
    light: AppleStoreIconLight,
    dark: AppleStoreIcon,
  },
};

function AppStoreBadge({ platform, variant }) {
  const Icon = Stores[platform][variant];
  return (
    <Icon />
  );
}

AppStoreBadge.defaultProps = {
  variant: 'dark',
};

AppStoreBadge.propTypes = {
  platform: PropTypes.oneOf(Object.keys(Stores)).isRequired,
  variant: PropTypes.oneOf(Object.keys(Stores.android)),
};

export default React.memo(AppStoreBadge);
