import React, {
  useContext, useRef, useEffect,
} from 'react';
import PropTypes from 'prop-types';
import { NuDsContext } from '@nubank/nuds-web/components/NuDSProvider/NuDSProvider';

import { typeValidation } from '../../utils';
import { MenuContext } from '../../MenuContext';

import ColumnLinkWrapper from './styles/ColumnLinkWrapper';

const ColumnLink = ({
  href,
  idNav,
  children,
  intlKey,
  onClick,
  ...props
}) => {
  const { formatMessage } = useContext(NuDsContext);

  const {
    setNavigationSectionActive,
    menuNavigationStructure,
    currentRoutePath,
  } = useContext(MenuContext);

  const ref = useRef(null);

  useEffect(() => {
    const isCurrentRoutePath = currentRoutePath === href;

    if (Boolean(idNav) && isCurrentRoutePath && menuNavigationStructure.length > 0) {
      const sectionId = Object.assign(...menuNavigationStructure
        .map(item => item.linksTexts?.find(subItem => subItem.linkId === idNav))
        .filter(item => item));

      const keySelected = sectionId.parentSectionId;
      setNavigationSectionActive(keySelected);
    }
  }, [menuNavigationStructure]);

  const hasIntlKey = Boolean(intlKey);
  const componentContent = hasIntlKey
    ? formatMessage({ id: intlKey })
    : children;

  const handleClick = event => {
    // workaround for adjusts focus-within
    event.target.blur();
    if (onClick) {
      onClick();
    }
  };

  return (
    <ColumnLinkWrapper
      ref={ref}
      href={href}
      onClick={handleClick}
      {...props}
    >
      {componentContent}
    </ColumnLinkWrapper>
  );
};

ColumnLink.propTypes = {
  // eslint-disable-next-line react/no-unused-prop-types
  __TYPE: typeValidation('ColumnLink'),
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  href: PropTypes.string.isRequired,
  idNav: PropTypes.string,
  intlKey: PropTypes.string,
  onClick: PropTypes.func,
};

ColumnLink.defaultProps = {
  __TYPE: 'ColumnLink',
  children: undefined,
  idNav: undefined,
  intlKey: undefined,
  onClick: undefined,
};

export default ColumnLink;

