import styled, { css } from 'styled-components';
import { breakpointsMedia } from '@nubank/nuds-web/styles/breakpoints';

export const LogoWrapper = styled.span`
  position: relative;
  top: 3px;

  ${breakpointsMedia({
    md: css`
      top: 0;
    `,
  })}
`;
