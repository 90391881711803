/* eslint-disable no-nested-ternary */
import React from 'react';
import PropTypes from 'prop-types';

import { typeValidation } from '../../utils';

import Section from './Section';
import SectionTitle from './SectionTitle';
import SectionLink from './SectionLink';
import Column from './Column';
import ColumnTitle from './ColumnTitle';
import ColumnLink from './ColumnLink';

export const NavigationKeyToComponentMap = {
  Section,
  SectionTitle,
  SectionLink,
  Column,
  ColumnTitle,
  ColumnLink,
};

function Navigation({ children }) {
  if (!children) {
    throw Error('Navigation should have children');
  }
  return (<></>);
}

Navigation.propTypes = {
  // eslint-disable-next-line react/no-unused-prop-types
  __TYPE: typeValidation('Navigation'),
  children: PropTypes.func.isRequired,
};

Navigation.defaultProps = {
  __TYPE: 'Navigation',
};

export default Navigation;
