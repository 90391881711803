import styled from 'styled-components';
import Link from '@nubank/nuds-web/components/Link/Link';

export const StyledAppLink = styled(Link)`
  & + & {
    margin-top: 1rem;
  }
`;

StyledAppLink.displayName = 'StyledAppLink';
