import { sendEvent } from '@nubank/www-latam-commons/services/analytics';
import { isBrowserEnv } from '@nubank/www-latam-commons/utils/env/isBrowserEnv';

export const trackTimeOnPage = () => {
  if (isBrowserEnv) {
    const sendTimeOnPage = () => {
      sendEvent('PAGE_LEAVE', { endtime: new Date().getTime() });
      window.removeEventListener('beforeunload', sendTimeOnPage);
    };
    window.addEventListener('beforeunload', sendTimeOnPage);
    return sendTimeOnPage;
  }
  return () => {};
};
