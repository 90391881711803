import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { useRouter } from 'next/router';
import { NuDsContext } from '@nubank/nuds-web/components/NuDSProvider/NuDSProvider';
import Link from '@nubank/nuds-web/components/Link/Link';
import NuLogo from '@nubank/nuds-web/components/NuLogo/NuLogo';
import { useBreakpointsMediaUp } from '@nubank/nuds-web/styles/breakpoints';
import { useIntl } from 'react-intl';

import Menu from 'components/Menu/Menu';
import RouterLink from 'components/RouterLink/RouterLink';
import { trackingClickMenu } from 'tracking/menu/clickInteraction';
import { DEFAULT_LOCALE } from 'utils/i18nUtils';
import Experiment from '@nubank/www-latam-commons/components/Experiment/Experiment';
import SearchButton from 'components/Menu/patterns/Navigation/SearchButton';

import { SEARCH_MVP } from '../../../utils/experiments';

import ApplicationButton from './components/ApplicationButton/ApplicationButton';
import { ActionsWrapper } from './styles/ActionsWrapper';
import { StyledChip } from './styles/StyledChip';
import { LogoWrapper } from './styles/LogoWrapper';

function MenuWrapper({
  showNavigation,
  showLogin,
  isOnPageFormVisible,
  hasOnPageForm,

}) {
  const router = useRouter();
  const { route } = router;
  const { formatMessage } = useContext(NuDsContext);
  const [isMenuMobileNavOpen, setMenuMobileNavOpen] = useState(false);
  const { locale } = useIntl();
  const isBrazil = locale === DEFAULT_LOCALE;

  const isRouteChipHighligthed = routePath => (
    routePath === route
      ? 'primary.default'
      : 'black.light'
  );

  const breakpointsUpLG = useBreakpointsMediaUp('lg');

  const toggleMobileMenu = isMenuMobileOpen => {
    const eventLabel = isMenuMobileOpen ? 'open_menu' : 'close_menu';
    setMenuMobileNavOpen(isMenuMobileOpen);
    trackingClickMenu(eventLabel);
  };

  const Logo = () => <NuLogo variant="primary" size="small" />;

  const logo = formatMessage({ id: 'cms.menu.logo' })[0];
  const login = formatMessage({ id: 'cms.menu.login' })[0];

  return (
    <>
      <Menu
        currentRoutePath={route}
        hideMobileMenu={!showNavigation}
        onToggleMobileMenu={isMenuMobileOpen => toggleMobileMenu(isMenuMobileOpen)}
        mobileButtonIconTitle={{
          titleClose: formatMessage({ id: 'cms.menu.closeMenuLabel' }),
          titleOpen: formatMessage({ id: 'cms.menu.openMenuLabel' }),
        }}
      >
        <Menu.Logo>
          {showNavigation ? (
            <LogoWrapper>
              <RouterLink
                href={logo.link}
                onClick={() => trackingClickMenu(logo.analyticsEvent)}
              >
                <Logo />
              </RouterLink>
            </LogoWrapper>
          ) : (
            <Logo />
          )}
        </Menu.Logo>
        <Menu.Actions>
          <ActionsWrapper
            height="100%"
            width="100%"
            minWidth="100px"
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="flex-end"
          >
            <Experiment id={SEARCH_MVP.id}>
              <Experiment.Variant id={SEARCH_MVP.variantA}>
                {(isBrazil && showNavigation && (isMenuMobileNavOpen || breakpointsUpLG)) && (
                  <SearchButton />
                )}
              </Experiment.Variant>
            </Experiment>
            {(showLogin && breakpointsUpLG) && (
              <Link
                iconProps={{ name: 'login' }}
                href={login.link}
                variant="action"
                onClick={() => trackingClickMenu(login.analyticsEvent)}
              >
                {login.text}
              </Link>
            )}
            {(!hasOnPageForm || (hasOnPageForm && !isOnPageFormVisible)) && (
              <ApplicationButton />
            )}
          </ActionsWrapper>
        </Menu.Actions>
        <Menu.ActionsSmallScreen>
          {({
            ActionLink,
          }) => (
            <>
              {showLogin && (
                <>
                  <ActionLink
                    iconProps={{ name: 'login' }}
                    href={login.link}
                    variant="action"
                    onClick={() => trackingClickMenu(login.analyticsEvent)}
                  >
                    {login.text}
                  </ActionLink>
                </>
              )}
            </>
          )}
        </Menu.ActionsSmallScreen>
        {showNavigation && (
          <Menu.Navigation>
            {({
              Section,
              SectionTitle,
              SectionLink,
              Column,
              ColumnTitle,
              ColumnLink,
            }) => (
              <>
                {formatMessage({ id: 'cms.menu.groups' }).map(({ _modelApiKey, ...group }) => (
                  _modelApiKey === 'menu_link' ? (
                    <Section>
                      <SectionLink
                        href={group.link}
                        onClick={() => trackingClickMenu(group.analyticsEvent)}
                      >
                        {group.text}
                      </SectionLink>
                    </Section>
                  ) : (
                    <Section>
                      <SectionTitle>{group.title}</SectionTitle>
                      {group.subcategories.map(subcategory => (
                        <Column>
                          <ColumnTitle>{subcategory.title}</ColumnTitle>
                          {subcategory.links.map(link => (
                            <ColumnLink
                              href={link.link}
                              onClick={() => trackingClickMenu(link.analyticsEvent)}
                              afterComponent={() => link.new && (
                                <StyledChip
                                  backgroundColor="primary.defaultL10"
                                  textColor={isRouteChipHighligthed('/cartao-pj')}
                                  text={formatMessage({ id: 'cms.menu.newChipLabel' })}
                                />
                              )}
                            >
                              {link.text}
                            </ColumnLink>
                          ))}
                        </Column>
                      ))}
                    </Section>
                  )
                ))}
              </>
            )}
          </Menu.Navigation>
        )}
      </Menu>
    </>
  );
}

MenuWrapper.defaultProps = {
  hasOnPageForm: false,
  isOnPageFormVisible: false,
  showLogin: false,
  showNavigation: false,
};

MenuWrapper.propTypes = {
  hasOnPageForm: PropTypes.bool,
  isOnPageFormVisible: PropTypes.bool,
  showLogin: PropTypes.bool,
  showNavigation: PropTypes.bool,
};

export default React.memo(MenuWrapper);
